import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { generateParamArray } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { UserSession } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UserSessionsService {
  constructor(private http: HttpClient) {}

  load() {
    return this.http.get('user/me/sessions') as Observable<
      IResponseSuccess<UserSession[]>
    >;
  }

  delete(sessionIds: number[]) {
    return this.http.delete(
      `user/me/sessions?${generateParamArray('session_id', sessionIds)}`,
    );
  }
}
