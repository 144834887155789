import { createFeatureSelector, createSelector } from '@ngrx/store';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const selectState = createFeatureSelector<State>('userSessions');

export const selectAllItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectById = (id: number) =>
  createSelector(selectState, (state: State) => state.entities[id]);

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);
