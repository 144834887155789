import { Action, createReducer, on } from '@ngrx/store';
import { differenceWith } from 'lodash';

import { UserSession } from '../../models';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { items }) => {
    return fromState.featureAdapter.setAll(
      items.map((item) => {
        return {
          ...item,
        };
      }),
      {
        ...state,
        isLoading: false,
        error: null,
      },
    );
  }),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.deleteRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),

  on(fromActions.deleteSuccess, (state, { sessionIds, userSessionId }) => {
    const sessions = differenceWith(
      state.entities[userSessionId].sessions,
      sessionIds,
      (a, b) => {
        return a.id === b;
      },
    );

    const userSession: UserSession = {
      ...state.entities[userSessionId],
      sessions,
    };
    return fromState.featureAdapter.upsertOne(userSession, {
      ...state,
      isLoading: false,
      error: null,
    });
  }),
  on(fromActions.deleteFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function userSessionsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
