import { createAction, props } from '@ngrx/store';

import { UserSession } from '../../models';

export const loadRequest = createAction('[User Sessions] Load Request');

export const loadSuccess = createAction(
  '[User Sessions] Load Success',
  props<{ items: UserSession[] }>(),
);

export const loadFailure = createAction(
  '[User Sessions] Load Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[User Sessions] Delete Request',
  props<{ userSessionId: number; sessionIds: number[] }>(),
);

export const deleteSuccess = createAction(
  '[User Sessions] Delete Success',
  props<{ userSessionId: number; sessionIds: number[] }>(),
);

export const deleteFailure = createAction(
  '[User Sessions] Delete Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[User Sessions] Reset State');
